import "./Contact.css";
import Header from "../components/Header/Header";

export default function Contact() {
  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <div className="block">
        <h1 className="contact-title">Contactez-nous</h1>
        <p className="contact-p">
          Pour toutes questions ou suggestions, contactez-nous à l'adresse
          suivante&nbsp;:&nbsp;&nbsp;
          <a href="mailto: ehkogames.fr@gmail.com">ehkogames.fr@gmail.com</a>
        </p>
        <br />
        <p className="contact-p">
          Vous pouvez également nous contacter sur Twitter&nbsp;:&nbsp;&nbsp;
          <a href="https://twitter.com/EhkoGames">@EhkoGames</a>
        </p>
        <br />
      </div>
    </>
  );
}
