import React from "react";
import { Link } from "react-router-dom";
import "./ArticlePreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

function ArticlePreview(props) {
  const g = "game?game=" + props.gameTitle;

  const getDate = () => {
    let date = new Date(props.publicationDate.seconds * 1000).toLocaleString('fr-fr', {year:"numeric", month:"short", day:"numeric", hour: "numeric", minute: "numeric"});
    if(date[1] === " ") {
      date = "0" + date;
    }
    return date;
  };

  function openMail(e) {
    e.preventDefault();
    window.location.href = `mailto:Ehkogames.erreurs@gmail.com?subject=Signalement sur l'article: ${props.articleTitle}&body=`;
  }
  return (
    <>
      <div id="container" className="container">
        <div className="innerContainer">
          <div className="product-image">
            {props.videoUrl !== "" ? (
              <iframe
                src={`https://www.youtube.com/embed/${props.videoUrl}`}
                title={props.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img src={props.imageUrl} alt="article" />
            )}
          </div>
          <div className="product-details">
            <p
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "15px",
                position: "absolute",
                top: "0",
                right: "5%",
                backgroundColor: props.backgroundColor,
                color: props.fontColor,
              }}
            >
              {props.bannerTxt}
            </p>
            {props.toLink === true ? (
              <Link to={g}>
                <h1 id="highlightTitle">{props.articleTitle}</h1>
                <hr className="separator-animated" />
              </Link>
            ) : (
              <>
                <h1 id="highlightTitle">{props.articleTitle}</h1>
                <hr className="separator-animated" />
              </>
            )}
            <br />

            <p
              className="information"
              dangerouslySetInnerHTML={{__html: props.articleText}}
            ></p>
          </div>
        </div>
        <div className="article-bottom">
          <hr
            style={{
              margin: 10,
              color: "#fff",
              height: 1,
              borderColor: "#fff",
            }}
          />
          <p>
            
            {getDate()}
            <FontAwesomeIcon icon={faFlag} onClick={openMail} className="report"/>
          </p>
        </div>
      </div>
    </>
  );
}

export default ArticlePreview;
