import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import "./FreeGames.css";
import StatusFreeGame from "./StatusFreeGame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

function FreeGames() {
  const goLeft = () => {
    document.getElementById("media-scroller").scrollLeft -= 225;
  };

  const goRight = () => {
    document.getElementById("media-scroller").scrollLeft += 225;
  };

  const [freeQuerySnapshot, setFreeQuerySnapshot] = useState([]);
  const [freeGames, setFreeGames] = useState([]);

  const loadFreeGames = async () => {
    try {
      const q = query(collection(db, "gamesPromo"), orderBy("endDate", "asc"));
      const doc = await getDocs(q);
      setFreeQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadFreeGames();
    // eslint-disable-next-line
  }, []);

  const getPublisher = (plateform) => {
    switch (plateform) {
      case "BattleNet":
        return "BattleNet.svg";
      case "EpicGames":
        return "EpicGames.svg";
      case "Gog":
        return "Gog.svg";
      case "EA":
        return "EA.svg";
      case "Steam":
        return "Steam.svg";
      case "Ubisoft":
        return "Ubisoft.svg";
      default:
        return "None.svg";
    }
  };

  const getActualTimer = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d === 1 ? " j " : " j ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    //console.log(dDisplay + hDisplay + mDisplay + sDisplay)

    if (seconds < 0) {
      return "Fini";
    }
    return dDisplay + hDisplay + mDisplay;
  };


  return (
    <div className="splide-container">
      <Splide options={{ perPage: 5 }}>
        {freeQuerySnapshot
          .filter(function (obj) {
            return (
              getActualTimer((Date.parse(obj.endDate) - Date.now()) / 1000) !==
              "Fini"
            );
          })
          .map((article, index) => {
            return (
              <SplideSlide key={index}>
                <a href={article.link} target="blank">
                  <div className="media-element">
                    <div className="group-image">
                      <img className="pochette" src={article.url} alt="" />
                      <img
                        className="publisher"
                        src={require(`../assets/logoPublishers/${getPublisher(
                          article.plateform
                        )}`)}
                        alt=""
                      />

                      <StatusFreeGame
                        text={article.bannerTxt}
                        color={article.fontColor}
                        bcColor={article.backgroundColor}
                      />
                    </div>
                    <p className="time-left-free-game">
                      {getActualTimer(
                        (Date.parse(article.endDate) - Date.now()) / 1000
                      )}
                    </p>
                  </div>
                </a>
              </SplideSlide>
            );
          })}
        {freeQuerySnapshot
          .filter(function (obj) {
            return (
              getActualTimer((Date.parse(obj.endDate) - Date.now()) / 1000) ===
              "Fini"
            );
          })
          .map((article, index) => {
            return (
              <SplideSlide key={index}>
                <a href={article.link} target="blank">
                  <div className="media-element">
                    <div className="group-image">
                      <img className="pochette" src={article.url} alt="" />
                      <img
                        className="publisher"
                        src={require(`../assets/logoPublishers/${getPublisher(
                          article.plateform
                        )}`)}
                        alt=""
                      />

                      <StatusFreeGame
                        text={article.bannerTxt}
                        color={article.fontColor}
                        bcColor={article.backgroundColor}
                      />
                    </div>
                    <p className="time-left-free-game">
                      {getActualTimer(
                        (Date.parse(article.endDate) - Date.now()) / 1000
                      )}
                    </p>
                  </div>
                </a>
              </SplideSlide>
            );
          })}
      </Splide>
    </div>
  );
}

export default FreeGames;
