import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import ArticlePreview from "../components/ArticlePreview";
import "./GamePage.css";
import GameHeader from "../components/GameHeader";
import Header from "../components/Header/Header";

function GamePage() {
  // const imageNum = Math.floor(Math.random() * (8 - 1 + 1)) + 1;

  const MAX_ARTICLES_TO_LOAD = 12;

  const [articlesLoaded, setArticlesLoaded] = useState(0);
  const [searchParams] = useSearchParams();
  const [lastVisible, setLastVisible] = useState(null);

  const [articlesQuerySnapshot, setArticlesQuerySnapshot] = useState([]);
  const [gameQuerySnapshot, setGameQuerySnapshot] = useState([]);

  const game = searchParams.get("game");
  //console.log(game);

  useEffect(() => {
    const toClear = document.querySelectorAll("div.searchElem");
    const toClear2 = document.getElementById("search-bar");
    for (let i = 0; i < toClear.length; i++) {
      toClear[i].style.display = "none";
    }
    toClear2.innerHtml = "";
    toClear2.innerText = "";
    toClear2.value = "";
    //console.log(toClear2)
  }, [game]);

  const loadGameInfo = async () => {
    try {
      const q = query(collection(db, "games"), where("title", "==", game));
      const doc = await getDocs(q);
      setGameQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      //setTitle(await gameQuerySnapshot.title)
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadArticlesData = async () => {
    try {
      const q = query(
        collection(db, "articles"),
        orderBy("publicationDate", "desc"),
        where("title", "==", game),
        limit(MAX_ARTICLES_TO_LOAD)
      );
      const doc = await getDocs(q);
      setArticlesQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setArticlesLoaded(doc.docs.length);
      setLastVisible(doc.docs[doc.docs.length - 1]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadMoreArticlesData = async () => {
    try {
      const q = query(
        collection(db, "articles"),
        orderBy("publicationDate", "desc"),
        where("title", "==", game),
        limit(MAX_ARTICLES_TO_LOAD),
        startAfter(lastVisible)
      );
      const doc = await getDocs(q);
      setArticlesQuerySnapshot(
        articlesQuerySnapshot.concat(
          doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
      console.log(doc.docs.length);
      setArticlesLoaded(doc.docs.length);
      setLastVisible(doc.docs[doc.docs.length - 1]);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    //load 20 first articles
    loadGameInfo();
    loadArticlesData();
    // eslint-disable-next-line
  }, [game]);

  return (
    <center>
      <div className="title-image-block">
        <Header />
        <br />
        <br />
        {gameQuerySnapshot.map((game, index) => (
          <GameHeader
            key={index}
            index={index}
            title={game.title}
            date={game.date}
            url={game.url}
            studio={game.studio}
            editor={game.editor}
            uppers={game.uppers}
            description={game.description}
            plateform={game.plateform}
          />
        ))}
      </div>
      {articlesQuerySnapshot.map((article, index) => {
        return (
          <ArticlePreview
            key={index}
            gameTitle={article.title}
            articleTitle={article.articleTitle}
            publicationDate={article.publicationDate}
            articleText={article.articleText}
            videoUrl={article.videoUrl}
            imageUrl={article.imageUrl}
            bannerTxt={article.bannerTxt}
            fontColor={article.fontColor}
            backgroundColor={article.backgroundColor}
            toLink={false}
          />
        );
      })}
      <br />

      {articlesLoaded === MAX_ARTICLES_TO_LOAD && (
      <button className="loadMore" onClick={loadMoreArticlesData}>

        Charger plus
      </button>
      )}
      <br />
      <br />
      <br />
      <br />
    </center>
  );
}

export default GamePage;
