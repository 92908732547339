import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { collection, query, getDocs, limit, where } from "firebase/firestore";
import "./ResearchGame.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchPreview from "./SearchPreview";

function ResearchGame() {
  const [search, setSearch] = useState("");
  const [gameQuerySnapshot, setGameQuerySnapshot] = useState([]);
  const [hasRes, setHasRes] = useState(true);

  const handleSearch = () => {
    console.log("Search ...", search);
    //go to game if only one res -> size() === 0
  };

  const loadGamesData = async () => {
    try {
      const q = query(
        collection(db, "games"),
        where("title", ">=", search),
        where("title", "<=", search + "\uf8ff"),
        limit(5)
      );
      const doc = await getDocs(q);

      if(doc.docs.length === 0) {
        setHasRes(false);
      } else {
        setHasRes(true);
      }
      setGameQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const toClear = () => {
    console.log("clear");
    setSearch("");
  };

  useEffect(() => {
    if (search.length === 0) {
      setHasRes(true);
      setGameQuerySnapshot([]);
    }
    if (search.length < 2) {return};
    setSearch(search);
    loadGamesData();

    
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      <div className="search-box">
        <button className="btn-search" onClick={() => handleSearch()}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
        <input
          id="search-bar"
          type="text"
          spellCheck="false"
          className="input-search"
          placeholder="Recherche un jeu"
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        ></input>

        <div
          className="searchResults"
          onClick={() => {
            toClear();
          }}
        >
          {hasRes &&
            gameQuerySnapshot.map((game, index) => (
              <SearchPreview
                key={index}
                index={index}
                title={game.title}
                image={game.url}
                uppers={game.uppers}
              />
            ))}

          { ! hasRes &&
            
              <SearchPreview
                key={0}
                index={0}
                title={"Aucun jeu trouvé"}
                image={""}
                uppers={""}
              />
            }
        </div>
      </div>
    </>
  );
}

export default ResearchGame;
