import React from 'react';
import "./NotFoundPage.css";

function NotFoundPage() {

    return (<>
        <h2 className='error404'>Erreur 404</h2>
        <div className='pError404'>Cette page n'existe pas ou a été supprimée</div>
        <a href="/" className='aError404'>Retourner à l'accueil</a>
    </>
    );
}

export default NotFoundPage;