import ResearchGame from "../ResearchGame";
// import Logo from "../../assets/Logo_Site_Ehko_Games.svg";
import Logo from "../../assets/Logo_Site.svg";
import LogoMail from "../../assets/logo/mail.png";
import LogoDiscord from "../../assets/logo/discord.png";
import LogoTwitter from "../../assets/logo/twitter.png";
import LogoPlayStore from "../../assets/logo/play-store.png";
import "./Header.css";

export default function Header() {
  return (
    <header>
      <nav>
        <div className="block-title-home">
          <a href="/">
            <img src={Logo} alt="" className="logo-top"></img>
          </a>
        </div>
        <div className="group-links">
          <div>
            <a href="/contact" className="centered-link-logo">
            <img src={LogoMail} alt="" className="logo-top"></img>
            </a>
          </div>
          <div>
            <a
              href="https://discord.gg/dd82Nxdztc"
              target="_blank"
              className="centered-link-logo"
            >
              <img src={LogoDiscord} alt="" className="logo-top"></img>
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/EhkoGames"
              target="_blank"
              className="centered-link-logo"
            >
              <img src={LogoTwitter} alt="" className="logo-top"></img>
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.hugoexpom.EhkoGames"
              target="_blank"
              className="centered-link-logo"
            >
              <img src={LogoPlayStore} alt="" className="logo-top"></img>
            </a>
          </div>
        </div>
        <ResearchGame />
      </nav>
    </header>
  );
}
