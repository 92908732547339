import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Fragment } from 'react';

import Home from "./pages/Home";
import GamePage from "./pages/GamePage";
import NotFoundPage from "./pages/NotFoundPage";
import Contact from "./pages/Contact";

function App() {


  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
