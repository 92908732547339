import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  startAfter,
  limit,
  where,
} from "firebase/firestore";
import ArticlePreview from "../components/ArticlePreview";
import FreeGames from "../components/FreeGames";

import "./Home.css";
import Header from "../components/Header/Header";

// const imageNum = Math.floor(Math.random() * (8 - 1 + 1)) + 1;

function Home() {

  const [counter, setCounter] = useState(20);

  const [articlesQuerySnapshot, setArticlesQuerySnapshot] = useState([]);
  const [articlesMoreQuerySnapshot, setArticlesMoreQuerySnapshot] = useState([]);


  const loadArticlesData = async () => {
    try {
      const q = query(
        collection(db, "articles"),
        orderBy("publicationDate", "desc"),
        where("onHome", "==", true),
        where("publicationDate", "<=", new Date()),
        limit(20)
      );
      const doc = await getDocs(q);
      setArticlesQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLoadMore = async () => {
    try {
      let q;
      if(articlesMoreQuerySnapshot.length === 0){
        q = query(
          collection(db, "articles"),
          orderBy("publicationDate", "desc"),
          where("onHome", "==", true),
          where("publicationDate", "<=", new Date()),
          startAfter(articlesQuerySnapshot[articlesQuerySnapshot.length - 1].publicationDate),
          limit(50)
        );
      } else {
        setArticlesQuerySnapshot([...articlesQuerySnapshot, ...articlesMoreQuerySnapshot]);
        q = query(
          collection(db, "articles"),
          orderBy("publicationDate", "desc"),
          where("onHome", "==", true),
          where("publicationDate", "<=", new Date()),
          startAfter(articlesMoreQuerySnapshot[articlesMoreQuerySnapshot.length - 1].publicationDate),
          limit(50)
        );
      }

      const doc = await getDocs(q);
      console.log(doc.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setArticlesMoreQuerySnapshot(
        doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setCounter(counter + 5);
    } catch (error) {
      console.log(error.message);
    }
  };


  useEffect(() => {
    //load 20 first articles
    loadArticlesData();
  }, []);

  return (
    <>
      <Header />
      <main>
        {/*Free games + Put articles*/}
        <br />
        <br />

        <FreeGames />

        

        <center>
          {articlesQuerySnapshot.map((article, index) => {
            return (
              <ArticlePreview
                key={index}
                gameTitle={article.title}
                articleTitle={article.articleTitle}
                publicationDate={article.publicationDate}
                articleText={article.articleText}
                videoUrl={article.videoUrl}
                imageUrl={article.imageUrl}
                bannerTxt={article.bannerTxt}
                fontColor={article.fontColor}
                backgroundColor={article.backgroundColor}
                toLink={true}
              />
            );
          })}
          {articlesMoreQuerySnapshot.map((article, index) => {
            return (
              <ArticlePreview
                key={index}
                gameTitle={article.title}
                articleTitle={article.articleTitle}
                publicationDate={article.publicationDate}
                articleText={article.articleText}
                videoUrl={article.videoUrl}
                imageUrl={article.imageUrl}
                bannerTxt={article.bannerTxt}
                fontColor={article.fontColor}
                backgroundColor={article.backgroundColor}
                toLink={true}
              />
            );
          })}
          <br />
          <br />
          <button className="loadMore" onClick={handleLoadMore}>Voir plus</button>
          <br />
          <br />
          <br />
          <br />
        </center>
      </main>
    </>
  );
}

export default Home;

