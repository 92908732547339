import "./SearchPreview.css";
import { Link } from "react-router-dom";

function SearchPreview(props) {

    const g = "/game?game=" + props.title;
      const getRealTitle = () => {
        //console.log(props.uppers)
        if(props.uppers !== undefined) {
          let res = "";
          let upp = props.uppers.split("-");

          let conter = 0;
          for(let i = 0; i < props.title.length; i++) {
            // eslint-disable-next-line
            if(i == upp[conter]) {
              res += props.title[i].toUpperCase();
              if(upp.length-1 > conter) {
                conter++;
              }
            } else {
              res += props.title[i];
            }
          }
          return res;
        } else {
          return props.title;
        }
      }

    return (
      <>
      {props.image !== "" ? <Link to={g} className="link-search-preview"><div className="searchElem" ><img className="imgSearchGame" src={props.image} alt="game"></img><span className="titleSearchGame">{props.title.toUpperCase()}</span></div></Link> 
      : <div className="searchElem" ><span className="titleSearchGame">{props.title.toUpperCase()}</span></div>}
      </>
    )
}

export default SearchPreview;