function GameHeader(props) {

  let plateforms = props.plateform?.split("...");
  
  if(plateforms === undefined || plateforms === null) {
    plateforms = [];
  } else {
    plateforms.pop();
  }

  const getRealTitle = () => {
    //console.log(props);
    if (props.uppers !== undefined) {
      let res = "";
      let upp = props.uppers.split("-");

      let conter = 0;
      for (let i = 0; i < props.title.length; i++) {
        // eslint-disable-next-line
        if (i == upp[conter]) {
          res += props.title[i].toUpperCase();
          if (upp.length - 1 > conter) {
            conter++;
          }
        } else {
          res += props.title[i];
        }
      }
      return res;
    } else {
      return props.title;
    }
  };

  return (
    <div className="inner-block-image-desc">
      <img className="image-pochette-game" src={props.url} alt=""></img>
      {/*<p className="description-game-page">{gameQuerySnapshot.description}</p>*/}
      <span className="container-table-description">
        <div className="title-in-table">{getRealTitle()}</div>
        <span className="plateform">{
          plateforms.map((plateform, index) => (
            <span key={index} className="plateform-item">{plateform + " "}</span>
          ))
        }
        </span>

        <div className="description">
          {props.description}
        </div>
        <div className="infos">
          {props.editor !== ":None:" && (
            <span>
              <span className="label-info">Développeur : </span> {props.studio}
            </span>
          )}
          {props.date !== ":None:" && (
            <span>
              <span className="label-info">Editeur : </span> {props.editor}
            </span>
          )}
          {props.date !== ":None:" && (
            <span>
              <span className="label-info">Sortie : </span> {props.date}
            </span>
          )}
        </div>
      </span>
    </div>
  );
}

export default GameHeader;
